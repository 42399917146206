import * as React from 'react';
import { navigate, Link } from "gatsby"
import { 
	Box,
	Button,
	Divider,
	IconButton,
	Grid,
	Typography,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import PapFooter from 'components/pap-footer.js';
import PapProjectsGrid from 'components/pap-projects-grid.js';
import LogoDowinWhite from "images/logodowinWhite.png";
import { auth, db } from '../../firebase-info';

// styles
const pageStyles = {
	fontFamily: 'Quicksand',
};
const topBar = {
	height: '56px',
	margin: '16px',
    marginTop: '0px',
}
const imageContainer = {
	height: '36.7px',
}
const headerMargin = {
	marginBottom: '20px',
	marginLeft: '16px',
	display: 'inline-block',
	verticalAlign: 'middle',
}
const logout = {
	marginBottom: '20px',
	marginLeft: 'auto',
	verticalAlign: 'middle',
}
const verticalPadding40 = {
	paddingTop: '40px',
	paddingBottom: '40px',
}
const blackButton = {
	fontFamily: 'Quicksand',
	fontSize: '24px',
	lineHeight: '32px',
	fontWeight: "700",
	backgroundColor: '#000000',
	width: '100%',
	borderRadius: '50px',
	paddingLeft: '55px',
	paddingRight: '55px',
	paddingTop: '21px',
	paddingBottom: '21px',
    marginTop: '27px',
}
const heavyBlue = {
    backgroundColor: '#2C90CB',
}
const sloganWhite = {
	fontFamily: 'Quicksand',
    color: '#FFFFFF',
	fontSize: '36px',
	lineHeight: '45px',
}
const headerLink = {
    textDecoration: 'none',
    color: '#FFF',
	fontWeight: 500,
}


// markup
const Dashboard = () => {
	const [values, setValues] = React.useState({
        hasDocuments: false,
		userRef: null,
		userSnapshot: null,
		orgRef: null,
		orgSnapshot: null,
	});
	const [loading, setLoading] = React.useState(true)
	const [rows, setRows] = React.useState([]);
	
	async function getDocumentData() {
		if (auth.currentUser === null) {
			setLoading(true);
		   	navigate("/solicitants");
		   	return;
		}

		try {
			const userRef = await db.collection(`users`).doc(auth.currentUser?.uid);
			const userSnapshot = await userRef.get();
			const orgRef = userSnapshot.get('orgRef');
			const orgSnapshot = await orgRef?.get();

			setValues({
				...values,
				hasDocuments: (orgSnapshot?.get('hasDocuments') || false),
				userRef,
				userSnapshot,
				orgRef,
				orgSnapshot,
			});
			setLoading(false);
		} catch {
			navigate("/solicitants");
			return;
		}
	};

	const formatDate = (date) => {
		return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
	}

	function performLogout() {
		auth.signOut();
		navigate("/");
	}

	async function getDataValues() {
		if (auth.currentUser === null) return;

		let currentUserDraws = null;
		let currentUserDrawsSnapshots = null;

		try {
			const userRef = values.userRef || await db.collection('users').doc(auth.currentUser?.uid);
			const userSnapshot = values.userSnapshot || await userRef.get();
			const orgRef = values.orgRef || userSnapshot.get('orgRef');
			const drawsOrgQueries = await db
			.collectionGroup("draws")
			.where('organizationId', '==' , orgRef)
			.get();
			currentUserDrawsSnapshots = drawsOrgQueries.docs;
			currentUserDraws = currentUserDrawsSnapshots.map((userDraw) => {
				const currentDate = new Date();
				let projectState = userDraw.get('closingDate').toDate() > currentDate ? 'En progreso' : 'Finalizado';
				const startDate = userDraw.get('startDate').toDate();
		
				if (startDate > currentDate) {
					const timeDifference = startDate.getTime() - currentDate.getTime();
					const days = Math.ceil(timeDifference / (1000 * 3600 * 24))
		
					if (days > 1) {
					projectState = `Comienza en ${days} días`
					} else {
					projectState = `¡Comienza mañana!`
					}
				}
		
				return {
					title: userDraw.get('title'),
					id: userDraw.id,
					totalTickets: userDraw.get('maxParticipations'),
					ticketsSold: userDraw.get('soldParticipations').length,
					type: userDraw.get('type'),
					startDate,
					endDate: userDraw.get('closingDate').toDate(),
					humanEndDate: formatDate(userDraw.get('closingDate').toDate()),
					humanStartDate: formatDate(startDate),
					humanDrawDate: formatDate(userDraw.get('drawDate').toDate()),
					drawDate: userDraw.get('drawDate').toDate(),
					description: userDraw.get('description'),
					orgDescription: userDraw.get('orgDescription'),
					projectState,
					imageUrl: userDraw.get('imageUrl'),
					imgDownloadUrl: userDraw.get('imgDownloadUrl'),
					price: userDraw.get('pricePerParticipation'),
					goal: parseFloat(userDraw.get('pricePerParticipation')) * parseFloat(userDraw.get('maxParticipations')),
				}
				});
		} catch (e) {
			currentUserDraws = [];
		}

		setRows(currentUserDraws);
	}
  
	React.useEffect(() => {
	  getDocumentData();
	  getDataValues();
	}, []);

	return (
		<main style={{backgroundColor: loading ? '#FFF' : '#000', height: '100%'}}>
			{loading ? 
				<></>
				:
				<>
					<Box style={pageStyles}>
						<Box style={heavyBlue}>
							<Grid container spacing={2} style={topBar}>
								<Grid item xs={11}>
									<Link to="/">
										<img
											alt="dowin Logo"
											style={imageContainer}
											src={LogoDowinWhite}
										/>
									</Link>
									<Box style={headerMargin}>
										<Typography fontFamily={"Quicksand"} color={"#FFFFFF"} display={"inline"}> Organizaciones y solicitantes </Typography>
									</Box>
									<Box style={headerMargin}>
										<Link style={headerLink} fontFamily={"Quicksand"} color={"#FFFFFF"} display={"inline"} to={"/profile"}> Mi perfil </Link>
									</Box>
								</Grid>
								<Grid item xs={1}>
									<IconButton style={logout} aria-label="Cerrar sesión" size="large">
										<LogoutIcon onClick={performLogout} fontSize="inherit" style={{fill: '#FFFFFF'}}/>
									</IconButton>
								</Grid>
							</Grid>
							<Grid style={verticalPadding40} justifyContent={"center"} container spacing={2}>
								<Grid item pb={"26px"} xs={8}>
									{!values.hasDocuments ?
										<>
											<Typography fontFamily={"Quicksand"} color={"#FFF"} variant='h6' component='h6'>
												Les solicitamos que terminen de completar sus datos en "Mi perfil” y suban los archivos solicitados. En un periodo de 24-48 horas validaremos su organización para que empiece a solicitar donaciones y celebrar sorteos.
											</Typography>
											<Divider/>
											<Typography fontFamily={"Quicksand"} color={"#FFF"} variant='body2' component='p'>
												Es necesario que suban los siguientes documentos:
											</Typography>
											<ul>
												<li style={{color: "#FFF"}}> Resolución administrativa de inscripción como organización (Obligatorio) </li>
												<li style={{color: "#FFF"}}> Modelo 036 (Obligatorio)</li>
												<li style={{color: "#FFF"}}> Justificante de estar al corriente de pagos con las administraciones públicas (Opcional)*</li>
											</ul>
											<Typography fontFamily={"Quicksand"} color={"#FFF"} variant='body2' component='p'>
												Si tienes cualquier problema nos puedes escribir a <a href='mailto:info@premiumandpremium.com'>info@premiumandpremium.com</a>
											</Typography>
											<Typography fontFamily={"Quicksand"} color={"#FFF"} variant='body2' component='p'>
												*En caso de no tener este documento nos podríamos ver obligados a retener los fondos de la organización en caso de requerimiento de la Hacienda Pública.
											</Typography>
									</>
									:
										<>
											<Typography style={sloganWhite}>Aquí puedes revisar el estado de tus proyectos creados, pudiendo acceder al detalle del mismo pulsando en la tabla</Typography>
											<br/>
											<Typography style={sloganWhite}>Recuerda que una vez un sorteo ha comenzado o está finalizado, no podrás realizar ningún cambio.</Typography>
											<br/>
											<Typography style={sloganWhite} display={"inline"}>Si tienes cualquier duda, por favor, escribe un email a </Typography>
											<Typography style={sloganWhite} display={"inline"} fontWeight={"700"}>info@premiumandpremium.com </Typography>
											<Typography style={sloganWhite} display={"inline"}>y te resolveremos cualquier incidencia tan pronto como nos sea posible.</Typography>
											<Box mb={"32px"}></Box>
												<Button style={blackButton} onClick={() => navigate("/project/select-project")} variant="contained">Crear un nuevo proyecto</Button>
											<Box mb={"32px"}></Box>
										</>
									}
								</Grid>
								{values.hasDocuments &&
									<Grid item xs={10}>
										<PapProjectsGrid rows={rows}/>
									</Grid>
								}
							</Grid>
						</Box>
					</Box>
					<PapFooter/>
				</>
			}
		</main>
	);
};

export default Dashboard;
