import React from 'react';
import { navigate } from "gatsby"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGrid, esES } from '@mui/x-data-grid';
import * as locales from '@mui/material/locale';

export default function PapProjectsGrid(props) {
	const rows = props.rows || [];

	const columns = [
		{ field: 'title', headerName: 'Nombre del proyecto', width: 275 },
		{ field: 'projectState', headerName: 'Estado del proyecto', width: 200 },
		{ field: 'ticketsSold', headerName: 'Donaciones recibidas', width: 175 },
		{ field: 'totalTickets', headerName: 'Donaciones solicitadas', width: 175 },
		{ field: 'price', headerName: 'Precio donación', width: 175 },
		{ field: 'goal', headerName: 'Meta objetivo', width: 175 },
		{ field: 'humanEndDate', headerName: 'Fecha de fin', width: 200 },
	];
	const theme = createTheme(
		{
			components: {
				MuiInputBase: {
					styleOverrides: {
						root: {
							fontFamily: 'Quicksand !important',
							color: '#FFFFFF',
						}
					}
				},
				MuiToolbar: {
					styleOverrides: {
						root: {
							fontFamily: 'Quicksand !important',
							color: '#FFFFFF',
						},
					},
				},
				MuiTablePagination: {
					styleOverrides: {
						selectLabel: {
							fontFamily: 'Quicksand !important',
							color: '#FFFFFF',
						},
						actions: {
							fontFamily: 'Quicksand !important',
							color: '#FFFFFF',
						},
						displayedRows: {
							fontFamily: 'Quicksand !important',
							color: '#FFFFFF',
						}
					},
				  },
			  },
			palette: {
				primary: { main: '#2C90CB' },
			},
		},
		locales['esES'],
		esES
	);
	const dataGridStyle = {
		fontFamily: 'Quicksand !important',
		color: '#FFFFFF',
	}

	return (
		<div style={{ width: '100%' }}>
			<ThemeProvider theme={theme}>
				<DataGrid
					onCellClick={(params, event) => {
						event.defaultMuiPrevented = true; 
						navigate(
							`/project/${params.id}`,
							{
								state: {
									projectData: params.row
								}
							}
						)
					}}
					sx={{
						'& .MuiDataGrid-cell': {
							cursor: 'pointer',
						}
					}}
					autoHeight={true}
					style={dataGridStyle}
					rows={rows}
					columns={columns}
				/>
			</ThemeProvider>
		</div>
	);
};